<template>
  <div class="bg-blue-50 px-10 py-8 mx-auto text-center">
    <p class="text-2xl md:text-3xl font-bold mb-2">Printables</p>
    <p class="text-lg max-w-3xl mx-auto mb-2">
      At Atlas, we're all about helping artists thrive with our awesome collection of printable resources. Whether you need to budget, manage a theatre production, or crush your goals, we've got you covered. Our resources are always growing and evolving to keep up with the vibrant Chicago theatre scene. Let's make magic happen together!
    </p>
    <p class="text-base italic">
      Are there printables you would love to see here?
      <a class="underline text-blue-500 hover:text-blue-950" href="mailto:info@chiartistatlas.com?subject=Inclusivity Resources">
        Let us know!
      </a>
    </p>
  </div>
  <nav class="flex flex-wrap justify-between text-base pb-2" aria-label="Breadcrumb">
    <ol role="list" class="items-center space-x-4 hidden sm:flex">
      <li>
        <div>
          <a href="/" class="text-gray-400 hover:text-gray-500">
            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
          <a href="/printables" class="ml-4 font-medium text-gray-500 hover:text-gray-700 underline">Printables</a>
        </div>
      </li>
    </ol>
  </nav>
  <div class="container pb-5 px-5 m-auto">
    <div class="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <article v-for="printable in printables" :key="printable.id" class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80">
          <img :src="printable.pic_url" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" />
          <div class="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
          <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

          <div class="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
            <!-- <time :datetime="post.datetime" class="mr-8">{{ post.date }}</time> -->
            <div class="-ml-4 flex items-center gap-x-4">
              <svg viewBox="0 0 2 2" class="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <!-- <div class="flex gap-x-2.5">
                <img :src="post.author.imageUrl" alt="" class="h-6 w-6 flex-none rounded-full bg-white/10" />
                {{ post.author.name }}
              </div> -->
            </div>
          </div>
          <h3 class="mt-3 text-lg font-semibold leading-6 text-white">
            <a :href="printable.url" target="_blank">
              <span class="absolute inset-0"></span>
              {{ printable.name }}
            </a>
          </h3>
        </article>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ChevronRightIcon, HomeIcon } from "@heroicons/vue/20/solid";
export default {
  components: { ChevronRightIcon, HomeIcon },
  data: function () {
    return {
      printables: []
    }
  }, 
  mounted() {
    this.getPrintables();
  },
  methods: {
    getPrintables() {
        axios.get("/printables.json").then((response) => {
          console.log(response.data);
          this.printables = response.data;
        });
    }
  }
}
</script>